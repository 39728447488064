<template>
    <ul v-if="items" role="menu">
        <template v-for="(item, i) of items">
            <li v-if="visible(item) && !item.separator" :key="item.label || i" :class="[{ 'layout-root-menuitem': root, 'active-menuitem': activeIndex === i && !item.disabled }]" role="menuitem">
                <div v-if="root && menuMode !== 'horizontal' && item.visible !== false">
                    <span class="layout-menuitem-text">{{ item.label }}</span>
                </div>
                <router-link
                    v-if="item.to"
                    v-ripple
                    :to="item.to"
                    :style="item.style"
                    :class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }]"
                    exact-active-class="active-route"
                    :target="item.target"
                    exact
                    @click="onMenuItemClick($event, item, i)"
                    @mouseenter="onMenuItemMouseEnter(i)"
                >
                    <i v-if="item.icon?.includes('pi-custom')" :class="['layout-menuitem-icon', item.icon]"><img alt="dropdown icon" class="vertical-align-sub" style="width: 1.25rem; filter: grayscale(1) opacity(0.75)" :src="item.icon_url" /></i>
                    <i v-else :class="['layout-menuitem-icon', item.icon]" />
                    <span class="layout-menuitem-text">{{ item.label }}</span>
                    <i v-if="item.items" class="pi pi-fw pi-angle-down layout-submenu-toggler" />
                </router-link>
                <a
                    v-if="!item.to"
                    v-ripple
                    :href="item.url || '#'"
                    :style="item.style"
                    :class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }]"
                    :target="item.target"
                    @click="onMenuItemClick($event, item, i)"
                    @mouseenter="onMenuItemMouseEnter(i)"
                >
                    <i :class="['layout-menuitem-icon', item.icon]" />
                    <span class="layout-menuitem-text">{{ item.label }}</span>
                    <i v-if="item.items" class="pi pi-fw pi-angle-down layout-submenu-toggler" />
                </a>
                <transition name="layout-menu">
                    <appsubmenu
                        v-show="item.items && (root && (menuMode !== 'horizontal' || (menuMode === 'horizontal' && (mobileMenuActive || activeIndex !== null))) ? true : activeIndex === i)"
                        :items="visible(item) && item.items"
                        :menu-active="menuActive"
                        :menu-mode="menuMode"
                        :parent-menu-item-active="activeIndex === i"
                        @menuitem-click="$emit('menuitem-click', $event)"
                    />
                </transition>
            </li>
        </template>
    </ul>
</template>

<script>
import EventBus from '@/event-bus';

export default {
    name: 'Appsubmenu',
    props: {
        items: Array,
        menuMode: String,
        menuActive: Boolean,
        mobileMenuActive: Boolean,
        root: {
            type: Boolean,
            default: false,
        },
        parentMenuItemActive: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['root-menuitem-click', 'menuitem-click'],
    data() {
        return {
            activeIndex: null,
        };
    },
    mounted() {
        EventBus.on('reset-active-index', () => {
            if (this.menuMode === 'horizontal') {
                this.activeIndex = null;
            }
        });
    },
    methods: {
        onMenuItemClick(event, item) {
            if (item.disabled) {
                event.preventDefault();
                return;
            }

            // execute command
            if (item.command) {
                item.command({ originalEvent: event, item: item });
                event.preventDefault();
            }

            if (item.items) {
                event.preventDefault();
            }

            if (this.root) {
                this.$emit('root-menuitem-click', {
                    originalEvent: event,
                });
            }

            // if (item.items) {
            //     this.activeIndex = index === this.activeIndex ? null : index;
            // } else {
            //     if (this.menuMode !== 'static') {
            //         const ink = this.getInk(event.currentTarget);
            //         if (ink) {
            //             this.removeClass(ink, 'p-ink-active');
            //         }
            //     }
            // }

            this.$emit('menuitem-click', {
                originalEvent: event,
                item: item,
            });
        },
        onMenuItemMouseEnter(index) {
            if (this.root && this.menuActive && this.menuMode === 'horizontal' && !this.isMobile()) {
                this.activeIndex = index;
            }
        },
        visible(item) {
            return typeof item.visible === 'function' ? item.visible() : item.visible !== false;
        },
        isMobile() {
            return window.innerWidth <= 1091;
        },
    },
};
</script>
