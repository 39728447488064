<template>
    <div class="layout-footer">
        <span>© <a href="https://www.risedigitalproperties.com" target="_new">Rise Digital Properties</a> and <a href="https://www.optakit.com" target="_new">Optakit</a></span>
    </div>
</template>

<script>
export default {
    name: 'AppFooter',
};
</script>
