<template>
    <div class="layout-menu-container">
        <AppSubmenu
            class="layout-menu"
            :items="model"
            :menu-mode="menuMode"
            :parent-menu-item-active="true"
            :menu-active="active"
            :mobile-menu-active="mobileMenuActive"
            :root="true"
            @menuitem-click="onMenuItemClick"
            @root-menuitem-click="onRootMenuItemClick"
        />
    </div>
</template>

<script>
import AppSubmenu from '@/AppSubmenu.vue';
export default {
    name: 'AppMenu',
    components: {
        AppSubmenu: AppSubmenu,
    },
    props: {
        model: {
            type: Array,
            default: () => [],
        },
        menuMode: String,
        active: Boolean,
        mobileMenuActive: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['menu-click', 'menuitem-click', 'root-menuitem-click'],
    methods: {
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },
        onRootMenuItemClick(event) {
            this.$emit('root-menuitem-click', event);
        },
    },
};
</script>
