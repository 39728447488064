import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getPerformance } from 'firebase/performance';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
// https://firebase.google.com/docs/web/setup#available-libraries

const productionConfig = {
    apiKey: 'AIzaSyCjNhhB28CTiJ4cgaMdgdYKdAmaEzZDF5M',
    authDomain: 'rise-client-hub.firebaseapp.com',
    projectId: 'rise-client-hub',
    storageBucket: 'rise-client-hub.appspot.com',
    messagingSenderId: '584064220621',
    appId: '1:584064220621:web:9d30cc71b6e8f61e041884',
    measurementId: 'G-JSQTT1S24N',
};

const developmentConfig = {
    apiKey: 'AIzaSyAI-OfwddVJcp3H9steFuB8uJZJ31zRDPY',
    authDomain: 'opta-dev.firebaseapp.com',
    projectId: 'opta-dev',
    storageBucket: 'opta-dev.appspot.com',
    messagingSenderId: '401252818351',
    appId: '1:401252818351:web:e903b72a94ce242abfa35a',
};

const firebaseConfig = import.meta.env.PROD ? productionConfig : developmentConfig;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const fireauth = getAuth(app);
const firedb = getFirestore();
const analytics = getAnalytics(app);
const fireperf = getPerformance(app);
const firestorage = getStorage(app);
const firefunctions = getFunctions(app, 'us-east4');

setPersistence(fireauth, browserLocalPersistence);

export { fireauth, firedb, analytics, fireperf, firefunctions, firestorage };
