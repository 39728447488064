<template>
    <div class="layout-breadcrumb">
        <div class="breadcrumb">
            <div class="route-bar-breadcrumb">
                <template v-for="(item, index) of items" :key="item">
                    <li>
                        <router-link v-if="item.routerLink" to="item.routerLink" class="p-link">
                            {{ item }}
                        </router-link>
                        <template v-if="!item.routerLink">
                            {{ item }}
                        </template>
                    </li>
                    <li v-if="index !== 'label'">
                        <i :class="['pi', { 'pi-angle-right': !$appState.isRTL, 'pi-angle-left': $appState.isRTL }]" />
                    </li>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            home: { icon: 'pi pi-home', to: '/' },
            items: [],
        };
    },
    watch: {
        $route() {
            this.watchRouter();
        },
    },
    created() {
        this.watchRouter();
    },
    methods: {
        watchRouter() {
            if (this.$router.currentRoute.value.meta.breadcrumb) {
                this.items = this.$router.currentRoute.value.meta.breadcrumb[0];
            }
        },
    },
};
</script>
