import { defineStore } from 'pinia';
import { fireauth, firedb } from '@/firebase';
import { doc, setDoc, getDocs, onSnapshot, where, collection, query, Timestamp } from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import { getEndpoint } from '@/environment';
import router from '@/router';
import * as Sentry from '@sentry/vue';

const clientsRef = collection(firedb, 'clients');

export const useNewStore = defineStore('NewStore', {
    state: () => {
        return {
            user: null,
            userDoc: null,
            orgDoc: null,
            contentDoc: null,
            membership: [],
            isStaff: false,
            error: null,
            // Unsub variables below to store and then detach listeners
            unsubUser: () => {
                Sentry.setUser(null);
                return;
            },
            unsubUserDoc: () => {
                return;
            },
            unsubOrgDoc: () => {
                return;
            },
        };
    },
    persist: true,
    getters: {
        isUserAuth: (state) => !!state.user,
    },
    actions: {
        async init() {
            try {
                await this.fetchUserDoc();
                if (!this.orgDoc) {
                    await this.fetchOrgDoc();
                }
                return;
            } catch (error) {
                this.handleError(error, 'Error during init');
            }
        },
        async fetchUserDoc() {
            if (!this.user) {
                return;
            }
            try {
                this.unsubUserDoc = await onSnapshot(doc(firedb, 'user', this.user.uid), async (doc) => {
                    this.userDoc = doc.data();
                    Sentry.setUser({ id: this.user.uid, email: this.userDoc?.user_email });
                });
            } catch (error) {
                this.handleError(error, 'Error fetching user document');
            }
        },
        async updateUserDoc(payload) {
            try {
                const userRef = doc(firedb, 'user', this.user.uid);
                await setDoc(userRef, payload, { merge: true });
            } catch (error) {
                this.handleError(error, 'Error updating user document');
            }
        },
        async fetchOrgDoc() {
            if (!this.userDoc) {
                setTimeout(async () => {
                    await this.fetchOrgDoc();
                    await this.fetchIsStaff();
                }, 250);
            }
            try {
                if (this.userDoc?.current_client?.length > 0) {
                    this.unsubOrgDoc = await onSnapshot(doc(firedb, 'clients', this.userDoc.current_client), async (doc) => {
                        this.orgDoc = doc.data();
                    });
                    return this.orgDoc;
                }
            } catch (error) {
                this.handleError(error, 'Error fetching organization document');
            }
        },
        async updateOrgDoc(payload, orgId) {
            try {
                const orgRef = doc(firedb, 'clients', orgId);
                await setDoc(orgRef, payload, { merge: true });
            } catch (error) {
                this.handleError(error, 'Error updating organization document');
            }
        },
        async getMemberships() {
            try {
                let tempMembership = [];
                const q = query(clientsRef, where('members', 'array-contains', this.user?.uid));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    tempMembership.push(doc.data());
                });
                this.membership = tempMembership;
            } catch (error) {
                this.handleError(error, 'Error fetching memberships');
            }
        },
        async fetchIsStaff() {
            try {
                const idTokenResult = await fireauth.currentUser.getIdTokenResult();
                this.isStaff = idTokenResult?.claims?.staff ? true : false;
            } catch (error) {
                this.handleError(error, 'Error checking staff status');
            }
        },
        async signOutAction() {
            try {
                signOut(fireauth)
                    .then(() => {
                        sessionStorage.clear();
                        Sentry.setUser(null);
                        router.push({ name: 'login' });

                        // The below is more secure for shared devices but slows load time. Also watchers need to be disconnected to avoid errors after
                        const store = useNewStore();
                        setTimeout(() => {
                            store.$reset();
                        }, 750);
                    })
                    .catch((error) => {
                        this.error = error.message;
                    });
            } catch (error) {
                this.handleError(error, 'Error during sign out');
            }
        },
        async endpointRequest(endpoint, json_data) {
            try {
                var url = getEndpoint(endpoint);
                var idToken = await fireauth.currentUser.getIdToken(true);
                let request_object = {
                    method: 'post',
                    headers: new Headers({
                        Authorization: idToken,
                        'Content-Type': 'application/json',
                    }),
                    body: JSON.stringify(json_data),
                };
                const requestResponse = await fetch(url, request_object)
                    .then(async (response) => {
                        let to_return = await response.json();
                        return to_return;
                    })
                    .catch((error) => {
                        console.error('Error on endpoint request:', error);
                        return null;
                    });
                return requestResponse;
            } catch (error) {
                this.handleError(error, 'Error during endpoint request');
                return null;
            }
        },
        async getCurrentSubscriptionDoc(sub_type) {
            let doc_dict = {};
            try {
                if (!this.orgDoc) {
                    await this.fetchOrgDoc();
                }
                const q = query(collection(firedb, 'subscriptions'), where('client_id', '==', this.orgDoc?.id), where('subscription_type', '==', sub_type));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    doc_dict = { id: doc.id, ...doc.data() };
                });
            } catch (error) {
                this.handleError(error, 'Error fetching subscription document');
            }
            return doc_dict;
        },
        handleError(error, message) {
            console.error(message, error);
            Sentry.captureException(error);
            this.error = message;
        },
        formatNumber(value) {
            return Number(value).toLocaleString('en-US');
        },
        formatPercent(value) {
            if (value !== undefined) {
                return Number(value).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 2 });
            } else {
                return value;
            }
        },
        formatDate(value) {
            value = new Date(value);
            return value.toLocaleTimeString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                timeZoneName: 'short',
            });
        },
        formatUTCDate(utcDate) {
            if (utcDate == null) return utcDate;

            if (utcDate instanceof Timestamp) {
                utcDate = utcDate.toDate(); // Convert Firestore timestamp to JavaScript Date
            } else {
                utcDate = utcDate.endsWith('UTC') ? utcDate.replace('UTC', '') : utcDate;
                utcDate = utcDate.endsWith('+0000') ? utcDate.replace('+0000', '') : utcDate;
                let utcDateWithZ = utcDate.endsWith('Z') ? utcDate : utcDate + 'Z';
                utcDate = new Date(utcDateWithZ);
            }

            return new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'America/New_York',
                timeZoneName: 'short',
            }).format(utcDate);
        },
        formatFirestoreTimestamp(timestamp) {
            if (!timestamp) {
                return null;
            }
            if (timestamp.seconds !== undefined && timestamp.nanoseconds !== undefined) {
                const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
                return date.toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    timeZone: 'America/New_York',
                    timeZoneName: 'short',
                });
            }

            return 'Invalid timestamp';
        },
        formatTimestamp(timestamp) {
            return timestamp.toDate().toLocaleString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'America/New_York',
                timeZoneName: 'short',
            });
        },
        formatStringTimestamp(stringTimestamp) {
            return new Date(Date.parse(stringTimestamp)).toLocaleString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'America/New_York',
                timeZoneName: 'short',
            });
        },
        formatCurrency(value) {
            if (!isNaN(value)) {
                return Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            } else {
                return '$0.00';
            }
        },
    },
});
