const branch = import.meta.env.MODE === 'branch' ? `-${import.meta.env.VITE_BRANCH_NAME}` : '';

export const sentry_env = import.meta.env.PROD ? 'production' : import.meta.env.VITE_BRANCH_NAME ? `${import.meta.env.VITE_BRANCH_NAME}` : 'dev';

export const projectID = import.meta.env.PROD ? 'rise-client-hub' : 'opta-dev';

export const getEndpoint = (endpoint, region = 'us-east4') => {
    return `https://${region}-${projectID}.cloudfunctions.net/${endpoint}${branch}`;
};

export const notificationClientID = import.meta.env.PROD ? '3mhb3tkja47i5f7d172813e6r9' : '2bsihhhn7imb6a27fiao4nerph';
